// AppRoutes.jsx
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Header } from '../components/Header/header';
import { ToastContainer } from 'react-toastify';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/login';
import Integracao from '../pages/Integracao/Integracao';
import PrivateRoute from '../components/Private/PrivateRoute';
import AdminRoute from '../components/Admin/AdminRoute';
import Contas from '../pages/MercadoLivre/Contas';
import Estoque from '../pages/Estoque/Estoque';
import Relatorios from '../pages/Relatorios/Relatorios';
import Vendas from '../pages/Vendas/Vendas';
import Produto from '../pages/Produtos/Produtos';
import Usuario from '../pages/Usuario';
import Ean from '../pages/Ean/Ean';
import { Sidebar } from '../components/Sidebar/Sidebar';
import RelatorioVendas from '../pages/Relatorios/RelatorioVendas';
import AdminPage from '../pages/Administracao/AdminPage';
import CategoriasPage from '../pages/Produtos/Categorias';
import ProductForm from '../pages/Produtos/components/ProductForm';
import RelatorioMovimentacaoEstoque from '../pages/Relatorios/RelatorioMovimentacaoEstoque';
import ComprasPage from '../pages/Compras/Compras';
import CompraForm from '../pages/Compras/CompraForm';
import CompraDetailPage from '../pages/Compras/CompraDetailPage';
import Anuncios from '../pages/Anuncios/Anuncios';
import VendasLocal from '../pages/Vendas/VendasLocal';
import EditMeliProduct from '../pages/Anuncios/components/EditMeliProduct';
import AnuncioEstoque from '../pages/Anuncios/AnuncioEstoque';
import AnuncioPauseEstoque from '../pages/Anuncios/AnuncioPauseEstoque';
import ForgotPassword from '../pages/Login/components';
import ResetPassword from '../pages/Login/ResetPassword';
import PermissionPage from '../pages/Permission';
import CapturaProduto from '../pages/CapturaProduto';
import { useAuth } from '../components/Private/AuthContext'; // Import your authentication context
import ModuloBusca from '../pages/Modulo/Busca';
import Noticias from '../pages/Noticias';
import MargemLucro from '../pages/Modulo/MargemLucro';
import Top20 from '../pages/Modulo/Top20/';
import TrendsModulo from '../pages/Modulo/Trends';

const AppRoutes = () => {
  const { user } = useAuth(); // Get the user object from your authentication context
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true);
        document.body.classList.remove('overlay-active');
      } else {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Determine whether to show Sidebar and Header based on roleModulos
  const shouldShowSidebarAndHeader =
    !isLoginRoute &&
    user &&
    user.roleModulo !== 'busca' &&
    user.roleModulo !== 'trial';

  const renderRoutes = () => {
    if (!user) {
      // User not logged in, redirect to login
      return <Route path="*" element={<Navigate to="/login" />} />;
    } else if (user.roleModulo === 'busca' || user.roleModulo === 'trial') {
      // Routes for users with roleModulo 'busca'
      return (
        <>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<ModuloBusca />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/Calculadora" element={<MargemLucro />} />
            <Route path="/Top20" element={<Top20 />} />
            <Route path="/Trends" element={<TrendsModulo />} />
          </Route>
        </>
      );
    } else {
      // Routes for other users
      return (
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/produtos" element={<Produto />} />
          <Route path="/produtos/cadastrar" element={<ProductForm />} />
          <Route path="/produtos/editar/:id" element={<ProductForm />} />
          <Route path="/integracao" element={<Integracao />} />
          <Route path="/contas" element={<Contas />} />
          <Route path="/estoque" element={<Estoque />} />
          <Route path="/relatorios" element={<Relatorios />} />
          <Route path="/vendas" element={<Vendas />} />
          <Route path="/vendas/vendasLocal" element={<VendasLocal />} />
          <Route path="/usuario" element={<Usuario />} />
          <Route path="/ean" element={<Ean />} />
          <Route path="/compras" element={<ComprasPage />} />
          <Route path="/compras/novo" element={<CompraForm />} />
          <Route path="/compras/editar/:id" element={<CompraForm />} />
          <Route path="/permissions/:userId" element={<PermissionPage />} />
          <Route path="/pesquisaMercado" element={<CapturaProduto />} />
          <Route path="/compras/detalhes/:id" element={<CompraDetailPage />} />
          <Route path="/produtos/categorias" element={<CategoriasPage />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/Calculadora" element={<MargemLucro />} />
          <Route path="/Top20" element={<Top20 />} />
          <Route path="/Trends" element={<TrendsModulo />} />
          <Route
            path="/relatorios/relatoriosVenda"
            element={<RelatorioVendas />}
          />
          <Route
            path="/relatorios/relatoriosMovimentacaoEstoque"
            element={<RelatorioMovimentacaoEstoque />}
          />
          <Route path="/anuncios" element={<Anuncios />} />
          <Route
            path="/anuncios/editar/:id"
            element={
              <EditMeliProduct
                onClose={() => {
                  /* logic to close modal or redirect */
                }}
                onSaveSuccess={() => {
                  /* logic after successful save */
                }}
              />
            }
          />
          {/* Protected route for admins */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />
        </Route>
      );
    }
  };

  return (
    <div
      className={`app ${
        isSidebarOpen && shouldShowSidebarAndHeader ? 'sidebar-open' : ''
      }`}
    >
      {shouldShowSidebarAndHeader && (
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      )}
      {shouldShowSidebarAndHeader &&
        isSidebarOpen &&
        window.innerWidth < 768 && (
          <div className="sidebar-overlay show" onClick={toggleSidebar}></div>
        )}
      <div className="content">
        {shouldShowSidebarAndHeader && (
          <Header onToggleSidebar={toggleSidebar} />
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {renderRoutes()}

          {/* These routes can be adjusted based on your requirements */}
          <Route path="/anuncios-vs-estoque/" element={<AnuncioEstoque />} />
          <Route
            path="/anunciospaused-vs-estoque/"
            element={<AnuncioPauseEstoque />}
          />
        </Routes>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '-20px' }}
      />
    </div>
  );
};

export default AppRoutes;
